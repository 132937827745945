<template>
	<div class="w-15-percent-cl-1 w-15-percent-cl-2 w-15-percent-cl-3 w-15-percent-cl-7">
	    <b-table small :fields="fields" :items="items" responsive="lg" tbody-tr-class="size-14 table-row" @row-clicked="detail" id="overview-table" class="mb-5 cursor-pointer" show-empty @head-clicked="sortClicked">
	    	<template #head(project_name)="data">
	    		<span>{{ data.label }}</span>
	    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc' }]" style="position: relative; left: 40px;">

		        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
		        </span>
	    	</template>
	    	<template #head(name)="data">
	    		<span>{{ data.label }}</span>
	    	</template>
	    	<template #head()="data">		    				    		
		        <div class="d-block text-center">
		        	<span class="text-center d-inline-block">{{ data.label }}</span>
			        <span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc' }]" style="position: relative; left: 15px;">

			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>
		        </div>
		    </template>	
			<template #cell(project_name)="data">
				<span class="maxTwoRow">{{ data.item.project_name }}</span>
			</template>
			<template #cell(name)="data">
				<span class="maxTwoRow">{{ data.item.name }}</span>
			</template>
			<template #cell(owner)="data">
				<span class="maxTwoRow text-center">{{ data.item.activity_owners }}</span>
			</template>
			<template #cell(type_service)="data">
				<template v-if="data.item.type_service">
					<span class="text-center maxTwoRow">{{ data.value.name }}</span>
				</template>				
			</template>
			<template #cell(status)="data">
				<span class="d-block text-center">
					<span v-if="data.item.status == 0">Not Done</span>
					<span v-else-if="data.item.status == 1">In Progress</span>
					<span v-else-if="data.item.status == 2">Done</span>
					<span v-else>Terminated</span>
				</span>
			</template>
			<template #cell(commencement_date)="data">
				<span class="d-block text-center">{{ data.item.commencement_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
			</template>
			<template #cell(completion_date)="data">
				<span class="d-block text-center">{{ data.item.completion_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
			</template>
			<template #cell()="data">
				<span class="d-block text-center">{{ data.value }}</span>
			</template>
			<template #empty="scope">
				<template v-if="search">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
				</template>
				<template v-else>
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
		    </template>			    
		</b-table>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="custom-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>			  
		    </div>
		</div> 
	</div>

</template>

<script>

	export default {
	  	props: {
            search: {
                type: String,
                default: null
            }
        },
	  	data() {
		    return {
                fields: [
			        { key: 'project_name', label: 'Project', isSort: true },
			        { key: 'name', label: 'Activity Name', isSort: false },
			        { key: 'type_service', label: 'Type of Services', isSort: true },
			        { key: 'status', label: 'Activity Status', isSort: true },
			        { key: 'commencement_date', label: 'Start Date', isSort: true },
			        { key: 'completion_date', label: 'End Date', isSort: true },
			        { key: 'owner', label: 'Activity Owner', isSort: true },
			    ],
		    	items: [],

			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		sort: {
		      		"field": "activity_name",
		      		"type": "asc"
		      	},
		      	limitpage: ['10', '20', '30'],
    			selected: '10',
            }
		},
		mounted () {
		    this.selected = '10'
		},
		watch: {
			currentPage(val) {
				this.fetchItem(this.search)
			},
			selected(val){
				if(val){
					this.perPage = val
					this.fetchItem(this.search)
				}
			},
		},
		created(){		
			this.fetchItem(this.search)
		},
		methods: {
		  	fetchItem(search){
		  		if(!search) search = ""
				this.$store.dispatch('project/listProjectActivity', {
					limit: this.perPage,
					page: this.currentPage,
					filter: {
						"list_search": [
						    {
						      "field": "search",
						      "keyword": search
						    }
						]
					},
					sort: this.sort
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			sortClicked(key, isSort) {
				if(isSort.isSort == true){
					if(this.sort.field == key) {
						if(this.sort.type == 'asc') this.sort.type = 'desc'
						else this.sort.type = 'asc'
					} else {
						this.sort.field = key
						this.sort.type = 'desc'
					}								
					this.fetchItem(this.search)
				}
			},
			detail(item){
				this.$router.push(`/admin/our-portfolio/project/${item.activity_country_id}/edit/${item.project_id}/activity/${item.id}`)
			},
		},
	};	

</script>